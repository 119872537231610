import './QuickEntryTeaserComponent.scss';

import React, { useContext, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import SiteDataContext from '../../contexts/SiteDataContext';
import Car from '../../styles/assets/images/static-vhv_telematik_teaser_final.webp';
import Lightbox from '../Lightbox/Lightbox';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import Container from '../ui/Container/Container';
import Icon from '../ui/Icon/Icon';
import Image from '../ui/Image/Image';
import Link from '../ui/Link/Link';
import YouTubeComponent from '../YouTubeComponent/YouTubeComponent';

const QuickEntryTeaserComponent = () => {
  const { navs = {}, pageScope } = useContext(SiteDataContext) || {};
  const nav = navs && pageScope && navs[pageScope] ? navs[pageScope] : [];
  const allProductsDoorPage = nav.find(mainNavItem => {
    const { template } = mainNavItem || {};
    return template === 'AllProductsDoorPage';
  });

  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const { children: privateMainNavItems } = allProductsDoorPage || {};
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);

  const handleSelectCategory = index => {
    if (Object.prototype.hasOwnProperty.call(privateMainNavItems[index], 'children')) {
      setSelectedInsurance(index);
      setShowDetails(true);
    }
  };

  const telematikLink = '/versicherungen/kfz-versicherung/auto/tarifrechner/';

  const telematikVideoId = 'By7pD92WsBQ';

  return (
    <Container>
      <Lightbox
        visible={isLightboxVisible}
        variants={['video']}
        turnOffHandler={() => setIsLightboxVisible(false)}
      >
        <YouTubeComponent id={telematikVideoId} />
      </Lightbox>
      <div className="QuickEntryTeaserComponent">
        <div className="QuickEntryTeaserComponent__telematicCard">
          <Image src={Car} />
          <h3>
            Mit Baustein <strong>TELEMATIK</strong> bis zu <strong>30% sparen</strong>
          </h3>
          <p>
            Mit sicherer Fahrweise und dem Baustein TELEMATIK bei Ihrer Kfz-Versicherung ganz
            einfach bares Geld beim Fahren sparen.
          </p>
          <div className="QuickEntryTeaserComponent__telematicCardButtonWrapper">
            <NewButtonComponent
              icon="calculator"
              linkTo={telematikLink}
              linkType="external"
              label="Jetzt berechnen"
            />
            <NewButtonComponent
              icon="video-play"
              label="Telematik in 2 Minuten erklärt"
              variant="light"
              onClick={() => setIsLightboxVisible(true)}
            />
          </div>
        </div>
        <div className="QuickEntryTeaserComponent__insuranceSection">
          <h2>
            <strong>Was</strong> möchten Sie <strong>absichern</strong>?
          </h2>
          <div>
            <CSSTransition in={!showDetails} timeout={200}>
              <div className="QuickEntryTeaserComponent__insurances">
                {privateMainNavItems &&
                  privateMainNavItems.slice(0, 5).map((insurance, index) => {
                    const directLink = Object.prototype.hasOwnProperty.call(insurance, 'children')
                      ? ''
                      : insurance.path;
                    return (
                      <Link
                        key={index}
                        linkTo={directLink}
                        extraClass="QuickEntryTeaserComponent__insuranceOverviewRow"
                        onClick={() => handleSelectCategory(index)}
                      >
                        <Icon
                          className="QuickEntryTeaserComponent__categoryIcon"
                          newSprites={true}
                          type={insurance.icon}
                        />
                        <div>{insurance.title}</div>
                        <Icon type="arrow-line-right" />
                      </Link>
                    );
                  })}
                <NewButtonComponent
                  linkTo="/versicherungen"
                  icon="arrow-line-right"
                  label="Alle Versicherungen entdecken"
                  variant="light"
                />
              </div>
            </CSSTransition>
            <CSSTransition in={showDetails} timeout={200}>
              <div className="QuickEntryTeaserComponent__insuranceDetail">
                <Icon type="arrow-line-left" onClick={() => setShowDetails(false)} />
                <div>
                  {selectedInsurance != null &&
                    privateMainNavItems[selectedInsurance].children.map(
                      (insuranceDetail, index) => {
                        return (
                          <Link key={index} linkTo={insuranceDetail.path}>
                            <div className="QuickEntryTeaserComponent__insuranceOverviewRow">
                              <Icon
                                className="QuickEntryTeaserComponent__categoryIcon"
                                newSprites={true}
                                type={insuranceDetail.icon}
                              />
                              <div>{insuranceDetail.title}</div>
                            </div>
                          </Link>
                        );
                      }
                    )}
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default QuickEntryTeaserComponent;
