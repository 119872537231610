import './KfzPrice2024StageComponent.scss';

import React from 'react';

import Barbara from '../../styles/assets/images/kfz-2024-stage/static-barbara-steering.webp';
import Background from '../../styles/assets/images/kfz-2024-stage/static-cars-percent-bg.webp';
import Trusted from '../../styles/assets/images/kfz-2024-stage/static-trusted-light.webp';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import Image from '../ui/Image/Image';

const KfzPrice2024StageComponent = () => {
  return (
    <header className={`KfzPrice2024StageComponent`}>
      <div className="KfzPrice2024StageComponent__inner">
        <div className="KfzPrice2024StageComponent__contentWrapper">
          <h1 className="KfzPrice2024StageComponent__headline">
            Die <strong>Autoversicherung,</strong>
            <br /> bei der Sie den Preis <strong>selbst lenken.</strong>
          </h1>
          <div className="KfzPrice2024StageComponent__buttonWrapper">
            <NewButtonComponent
              icon="calculator"
              linkTo={'/versicherungen/kfz-versicherung/auto/tarifrechner'}
              linkType="external"
              label="Jetzt Beitrag berechnen"
            />
            <NewButtonComponent
              icon="info"
              linkTo={'https://www.vhv.de/wf/lp/autoversicherung'}
              label="Mehr erfahren"
              variant="white"
            />
          </div>
          <div className="KfzPrice2024StageComponent__trustedWrapper">
            <Image src={Trusted} />
          </div>
        </div>
        <Image extraClass="KfzPrice2024StageComponent__background" src={Background} />
        <Image extraClass="KfzPrice2024StageComponent__barbara" src={Barbara} />
      </div>
    </header>
  );
};

export default KfzPrice2024StageComponent;
