/* eslint-disable simple-import-sort/imports */
import { FunctionComponent } from 'react';

import AgendaArea from '../areas/AgendaArea/AgendaArea';
import Area from '../areas/Area';
import ArticlesArea from '../areas/ArticlesArea/ArticlesArea';
import BenefitArea from '../areas/BenefitArea/BenefitArea';
import BenefitTableFooterArea from '../areas/BenefitTableFooterArea/BenefitTableFooterArea';
import BillboardArea from '../areas/BillboardArea/BillboardArea';
import ContactArea from '../areas/ContactArea/ContactArea';
import ContentArea from '../areas/ContentArea/ContentArea';
import ContentHeaderArea from '../areas/ContentHeaderArea';
import CrossSellingArea from '../areas/CrossSellingArea/CrossSellingArea';
import DescriptionArea from '../areas/DescriptionArea/DescriptionArea';
import DirectoryArea from '../areas/DirectoryArea/DirectoryArea';
import DownloadArea from '../areas/DownloadArea/DownloadArea';
import EventsArea from '../areas/EventsArea/EventsArea';
import FooterArea from '../areas/FooterArea/FooterArea';
import FooterColumnsArea from '../areas/FooterColumnsArea/FooterColumnsArea';
import FooterContactArea from '../areas/FooterContactArea/FooterContactArea';
import FooterLinkArea from '../areas/FooterLinkArea/FooterLinkArea';
import FurtherLinksArea from '../areas/FurtherLinksArea/FurtherLinksArea';
import GalleryArea from '../areas/GalleryArea/GalleryArea';
import EventHeaderArea from '../areas/HeaderArea/EventHeaderArea';
import ExpertSearchHeaderArea from '../areas/HeaderArea/ExpertSearchHeaderArea';
import HeaderArea from '../areas/HeaderArea/HeaderArea';
import IntroTextArea from '../areas/IntroTextArea/IntroTextArea';
import LeadTextArea from '../areas/LeadTextArea/LeadTextArea';
import LinksArea from '../areas/LinksArea/LinksArea';
import MapArea from '../areas/MapArea/MapArea';
import PartnerArea from '../areas/PartnerArea/PartnerArea';
import PointArea from '../areas/PointArea/PointArea';
import PricePreviewArea from '../areas/PricePreviewArea/PricePreviewArea';
import ProductArea from '../areas/ProductArea/ProductArea';
import ProductVariantsArea from '../areas/ProductVariantsArea/ProductVariantsArea';
import RatgeberArea from '../areas/RatgeberArea/RatgeberArea';
import RatingsArea from '../areas/RatingsArea/RatingsArea';
import RelatedContentArea from '../areas/RelatedContentArea/RelatedContentArea';
import SignetArea from '../areas/SignetArea/SignetArea';
import SitemapArea from '../areas/SitemapArea/SitemapArea';
import SocialSharingArea from '../areas/SocialSharingArea/SocialSharingArea';
import SolutionArea from '../areas/SolutionArea/SolutionArea';
import BusinessCardPageStageArea from '../areas/StageArea/BusinessCardPageStageArea';
import CalculatorSeoPageStageArea from '../areas/StageArea/CalculatorSeoPageStageArea';
import LandingPageStageArea from '../areas/StageArea/LandingPageStageArea';
import StageArea from '../areas/StageArea/StageArea';
import SubHeaderArea from '../areas/SubHeaderArea/SubHeaderArea';
import TeaserArea from '../areas/TeaserArea/TeaserArea';
import TestimonialArea from '../areas/TestimonialArea/TestimonialArea';
import BenefitComponent from '../components/BenefitComponent/BenefitComponent';
import BenefitTableComponent from '../components/BenefitTableComponent/BenefitTableComponent';
import FaqFooterComponent from '../components/FaqFooterComponent/FaqFooterComponent';
import Phv2022StageComponent from '../components/Phv2022StageComponent/Phv2022StageComponent';
import Icon from '../components/ui/Icon/Icon';
import WohngebStageComponent from '../components/WohngebStageComponent/WohngebStageComponent';
import ArticleOverviewPage from '../containers/ArticleOverviewPage';
import ArticlePage from '../containers/ArticlePage';
import BrokerSearchPage from '../containers/BrokerSearchPage';
import BrokerSurveyPage from '../containers/BrokerSurveyPage';
import BusinessCardPage from '../containers/BusinessCardPage';
import CalculatorSeoPage from '../containers/CalculatorSeoPage';
import ContactDoorPage from '../containers/ContactDoorPage';
import ContainerPage from '../containers/ContainerPage';
import ContentPage from '../containers/ContentPage';
import CorporateHomePage from '../containers/CorporateHomePage';
import CorporateProductPage from '../containers/CorporateProductPage';
import CustomPage from '../containers/CustomPage';
import CyberLandingPage from '../containers/CyberLandingPage';
import DoorPage from '../containers/DoorPage';
import ErrorPage from '../containers/ErrorPage';
import EventOverviewPage from '../containers/EventOverviewPage';
import EventPage from '../containers/EventPage';
import ExpertDirectoryPage from '../containers/ExpertDirectoryPage';
import ExpertHomePage from '../containers/ExpertHomePage';
import ExpertPartnerPage from '../containers/ExpertPartnerPage';
import ExpertSolutionPage from '../containers/ExpertSolutionPage';
import FormPage from '../containers/FormPage';
import GuidebookPage from '../containers/GuidebookPage';
import PrivateHomePage from '../containers/PrivateHomePage';
import HomePage from '../containers/HomePage';
import LandingPage from '../containers/LandingPage';
import LossDoorPage from '../containers/LossDoorPage';
import MicrositePage from '../containers/MicrositePage';
import ProductPage from '../containers/ProductPage';
import ProductPrivatePage from '../containers/ProductPrivatePage';
import RatingsPage from '../containers/RatingsPage';
import SeoProductPage from '../containers/SeoProductPage';
import SitemapPage from '../containers/SitemapPage';
import AgendaItemComponent from './AgendaItemComponent/AgendaItemComponent';
import ArticlesComponent from './ArticlesComponent/ArticlesComponent';
import BenefitCellComponent from './BenefitCellComponent/BenefitCellComponent';
import BenefitOptionComponent from './BenefitOptionComponent/BenefitOptionComponent';
import BenefitOptionsComponent from './BenefitOptionsComponent/BenefitOptionsComponent';
import BenefitPointComponent from './BenefitPointComponent/BenefitPointComponent';
import BenefitPointsComponent from './BenefitPointsComponent/BenefitPointsComponent';
import BoxComponent from './BoxComponent/BoxComponent';
import BrandingHeaderComponent from './BrandingHeaderComponent/BrandingHeaderComponent';
import BrokerSearchCallToActionComponent from './BrokerSearchCallToActionComponent/BrokerSearchCallToActionComponent';
import BrokerSearchComponent from './BrokerSearchComponent/BrokerSearchComponent';
import ButtonComponent from './ButtonComponent/ButtonComponent';
import CalculatorLinkComponent from './CalculatorLinkComponent/CalculatorLinkComponent';
import CalculatorLinksComponent from './CalculatorLinksComponent/CalculatorLinksComponent';
import CallToActionComponent from './CallToActionComponent/CallToActionComponent';
import ColumnComponent from './ColumnComponent/ColumnComponent';
import ColumnsComponent from './ColumnsComponent/ColumnsComponent';
import ComparisonCellComponent from './ComparisonCellComponent/ComparisonCellComponent';
import ComparisonComponent from './ComparisonComponent/ComparisonComponent';
import ComparisonComponentNew from './ComparisonComponentNew/ComparisonComponentNew';
import ComparisonRowComponent from './ComparisonRowComponent/ComparisonRowComponent';
import ComparisonRowComponentNew from './ComparisonRowComponentNew/ComparisonRowComponentNew';
import ComparisonTableComponent from './ComparisonTableComponent/ComparisonTableComponent';
import ContactBoxButtonComponent from './ContactBoxButtonComponent/ContactBoxButtonComponent';
import ContactBoxComponent from './ContactBoxComponent/ContactBoxComponent';
import ContactComponent from './ContactComponent/ContactComponent';
import ContentInfoBox from './ContentInfoBox/ContentInfoBox';
import ContentListComponent from './ContentListComponent/ContentListComponent';
import ContentListItem from './ContentListItem/ContentListItem';
import ContentTeaserComponent from './ContentTeaserComponent/ContentTeaserComponent';
import CyberInfoComponent from './CyberInfoComponent/CyberInfoComponent';
import DisqTeaserComponent from './DisqTeaserComponent/DisqTeaserComponent';
import DownloadComponent from './DownloadComponent/DownloadComponent';
import UspComponent from './UspComponent/UspComponent';
import DownloadsComponent from './DownloadsComponent/DownloadsComponent';
import EventsComponent from './EventsComponent/EventsComponent';
import FaqComponent from './FaqComponent/FaqComponent';
import CustomerPortalTeaserComponent from './CustomerPortalTeaserComponent/CustomerPortalTeaserComponent';
import GuidebookTeaserComponent from './GuidebookTeaserComponent/GuidebookTeaserComponent';
import InsuranceAddonsComponent from './InsuranceAddonsComponent/InsuranceAddonsComponent';
import KfzInsuranceTypesComponent from './KfzInsuranceTypesComponent/KfzInsuranceTypesComponent';
import ContactTeaserComponent from './ContactTeaserComponent/ContactTeaserComponent';
import QuickEntryTeaserComponent from './QuickEntryTeaserComponent/QuickEntryTeaserComponent';
import FooterColumnComponent from './FooterColumnComponent/FooterColumnComponent';
import FooterLinkComponent from './FooterLinkComponent/FooterLinkComponent';
import GalleryComponent from './GalleryComponent/GalleryComponent';
import HausratStageComponent from './HausratStageComponent/HausratStageComponent';
import HomeSectionComponent from './HomeSectionComponent/HomeSectionComponent';
import ImageComponent from './ImageComponent/ImageComponent';
import LargeImageComponent from './LargeImageComponent/LargeImageComponent';
import LargeTeaserComponent from './LargeTeaserComponent/LargeTeaserComponent';
import LinkComponent from './LinkComponent/LinkComponent';
import LinkListComponent from './LinkListComponent/LinkListComponent';
import MinimumRateComponent from './MinimumRateComponent/MinimumRateComponent';
import NewsletterRegistrationDownloadComponent from './NewsletterRegistrationDownloadComponent/NewsletterRegistrationDownloadComponent';
import NpsComponent from './NpsComponent/NpsComponent';
import OrganizationComponent from './OrganizationComponent/OrganizationComponent';
import OrganizationsComponent from './OrganizationsComponent/OrganizationsComponent';
import Phv2024StageComponent from './Phv2024StageComponent/Phv2024StageComponent';
import PhvBarbaraStageComponent from './PhvBarbaraStageComponent/PhvBarbaraStageComponent';
import PriceCallToActionComponent from './PriceCallToActionComponent/PriceCallToActionComponent';
import ProductVariantComponent from './ProductVariantComponent/ProductVariantComponent';
import QuestionComponent from './QuestionComponent/QuestionComponent';
import QuickCheckComponent from './QuickCheckComponent/QuickCheckComponent';
import QuoteComponent from './QuoteComponent/QuoteComponent';
import RatgeberTeaserComponent from './RatgeberTeaserComponent/RatgeberTeaserComponent';
import RatingsComponent from './RatingsComponent/RatingsComponent';
import RequirementCalculatorComponent from './RequirementCalculatorComponent/RequirementCalculatorComponent';
import RichTextComponent from './RichTextComponent/RichTextComponent';
import SectionComponent from './SectionComponent/SectionComponent';
import SelectedOrganizationsComponent from './SelectedOrganizationsComponent/SelectedOrganizationsComponent';
import SignetComponent from './SignetComponent/SignetComponent';
import SignetSliderComponent from './SignetSliderComponent/SignetSliderComponent';
import SmallCallToActionComponent from './SmallCallToActionComponent/SmallCallToActionComponent';
import StaticStageComponent from './StaticStageComponent/StaticStageComponent';
import Sustainability2024StageComponent from './Sustainability2024StageComponent/Sustainability2024StageComponent';
import TeaserComponent from './TeaserComponent/TeaserComponent';
import TeaserListComponent from './TeaserListComponent/TeaserListComponent';
import TelematikStageComponent from './TelematikStageComponent/TelematikStageComponent';
import TestimonialComponent from './TestimonialComponent/TestimonialComponent';
import TileComponent from './TileComponent/TileComponent';
import TilesComponent from './TilesComponent/TilesComponent';
import AddressFieldComponent from './ui/Form/AddressFieldComponent';
import CheckboxComponent from './ui/Form/CheckboxComponent';
import DateFieldComponent from './ui/Form/DateFieldComponent';
import EmailFieldComponent from './ui/Form/EmailFieldComponent';
import FormErrorMessage from './ui/Form/FormErrorMessage';
import FormTooltip from './ui/Form/FormTooltip';
import HeadlineComponent from './ui/Form/HeadlineComponent';
import LocalityFieldComponent from './ui/Form/LocalityFieldComponent';
import RadioGroupComponent from './ui/Form/RadioGroupComponent';
import SelectFieldComponent from './ui/Form/SelectFieldComponent';
import TextComponent from './ui/Form/TextComponent';
import TextFieldComponent from './ui/Form/TextFieldComponent';
import Link from './ui/Link/Link';
import YouTubeComponent from './YouTubeComponent/YouTubeComponent';
import FrequentlyAskedComponent from './FrequentlyAskedComponent/FrequentlyAskedComponent';
import SignetCardsComponent from './SignetCardsComponent/SignetCardsComponent';
import MediaTeaserComponent from './MediaTeaserComponent/MediaTeaserComponent';
import ProductStageComponent from './ProductStageComponent/ProductStageComponent';
import WhyVHVComponent from './WhyVHVComponent/WhyVHVComponent';
import KfzPrice2024StageComponent from './KfzPrice2024StageComponent/KfzPrice2024StageComponent';
import FlotteStageOld from './ui/FlotteStageOld/FlotteStageOld';

export interface ComponentDef {
  component: { [key: string]: FunctionComponent<any> };
  childrenNode?: string;
}

const componentDefs: ComponentDef[] = [
  // { component: { SurveyArea } },
  { component: { AddressFieldComponent } },
  { component: { AgendaArea } },
  { component: { AgendaItemComponent } },
  { component: { AllProductsDoorPage: DoorPage } },
  { component: { Area } },
  { component: { ArticleFolderPage: ContainerPage } },
  { component: { ArticleOverviewPage } },
  { component: { ArticlePage } },
  { component: { ArticlesArea } },
  { component: { ArticlesComponent } },
  { component: { BcOrganizationComponent: OrganizationComponent } },
  { component: { BenefitArea } },
  { component: { BenefitCellComponent } },
  { component: { BenefitComponent } },
  { component: { BenefitOptionComponent } },
  { component: { BenefitOptionsComponent } },
  { component: { BenefitPointComponent } },
  { component: { BenefitPointsComponent } },
  { component: { BenefitTableComponent } },
  { component: { BenefitTableFooterArea } },
  { component: { BillboardArea } },
  { component: { BoxComponent } },
  { component: { BrandingHeaderComponent } },
  { component: { BrokerSearchCallToActionComponent } },
  { component: { BrokerSearchComponent } },
  { component: { BrokerSearchPage } },
  { component: { BrokerSurveyPage } },
  { component: { FrequentlyAskedComponent } },
  { component: { BusinessCardPage } },
  { component: { BusinessCardPageStageArea } },
  { component: { BusinessCardSectionComponent: SectionComponent } },
  { component: { ButtonComponent } },
  { component: { CalculatorLinkComponent } },
  { component: { CalculatorLinksComponent } },
  { component: { CalculatorSeoPage } },
  { component: { CalculatorSeoPageStageArea } },
  { component: { CallToActionComponent } },
  { component: { CheckboxComponent } },
  { component: { ColumnComponent } },
  { component: { ColumnsComponent } },
  { component: { ComparisonCellComponent } },
  { component: { ComparisonComponent } },
  { component: { ComparisonComponentNew } },
  { component: { ComparisonRowComponent } },
  { component: { ComparisonRowComponentNew } },
  { component: { ComparisonTableComponent } },
  { component: { ContactArea } },
  { component: { ContactBoxButtonComponent } },
  { component: { ContactBoxComponent } },
  { component: { ContactComponent } },
  { component: { ContactDoorPage } },
  { component: { ContainerPage } },
  { component: { ContentArea } },
  { component: { ContentHeaderArea } },
  { component: { ContentInfoBox } },
  { component: { ContentInfoBox } },
  { component: { ContentListComponent } },
  { component: { ContentListComponent } },
  { component: { ContentListItem } },
  { component: { ContentPage } },
  { component: { ContentTeaserComponent } },
  { component: { CorporateHomePage } },
  { component: { CorporateHomeSectionComponent: SectionComponent } },
  { component: { CorporateProductPage } },
  { component: { CorporateProductSectionComponent: SectionComponent } },
  { component: { CrossSellingArea } },
  { component: { CustomPage } },
  { component: { CyberInfoComponent } },
  { component: { CyberLandingPage } },
  { component: { DateFieldComponent } },
  { component: { DescriptionArea } },
  { component: { DirectoryArea } },
  { component: { DisqTeaserComponent } },
  { component: { DoorPage } },
  { component: { DownloadArea } },
  { component: { DownloadComponent } },
  { component: { DownloadsComponent } },
  { component: { UspComponent } },
  { component: { EmailFieldComponent } },
  { component: { ErrorPage } },
  { component: { EventHeaderArea } },
  { component: { EventOverviewPage } },
  { component: { EventPage } },
  { component: { EventsArea } },
  { component: { EventsComponent } },
  { component: { ExpertDirectoryPage } },
  { component: { ExpertFooterContactArea: FooterContactArea } },
  { component: { ExpertHomePage } },
  { component: { ExpertHomeSectionComponent: SectionComponent } },
  { component: { ExpertPartnerPage } },
  { component: { ExpertSearchHeaderArea } },
  { component: { ExpertSolutionPage } },
  { component: { FaqComponent } },
  { component: { CustomerPortalTeaserComponent } },
  { component: { GuidebookTeaserComponent } },
  { component: { InsuranceAddonsComponent } },
  { component: { KfzInsuranceTypesComponent } },
  { component: { ContactTeaserComponent } },
  { component: { QuickEntryTeaserComponent } },
  { component: { FaqFooterComponent } },
  { component: { FlotteStageOld } },
  { component: { FooterArea } },
  { component: { FooterColumnComponent } },
  { component: { FooterColumnsArea } },
  { component: { FooterContactArea } },
  { component: { FooterLinkArea } },
  { component: { FooterLinkComponent } },
  { component: { FormErrorMessage } },
  { component: { FormPage } },
  { component: { FormTooltip } },
  { component: { FurtherLinksArea } },
  { component: { GalleryArea } },
  { component: { GalleryComponent } },
  { component: { GuidebookPage } },
  { component: { HeaderArea } },
  { component: { HeadlineComponent } },
  { component: { PrivateHomePage } },
  { component: { HomePage } },
  { component: { HomeSectionComponent } },
  { component: { Icon } },
  { component: { ImageComponent } },
  { component: { KfzPrice2024StageComponent } },
  { component: { MediaTeaserComponent } },
  { component: { ProductStageComponent } },
  { component: { IntroTextArea } },
  { component: { LageTeaserComponent: LargeTeaserComponent } },
  { component: { LandingPage } },
  { component: { LandingPageStageArea } },
  { component: { LandingSectionComponent: SectionComponent } },
  { component: { LargeImageComponent } },
  { component: { LargeTeaserComponent } },
  { component: { LeadTextArea } },
  { component: { Link } },
  { component: { LinkComponent } },
  { component: { LinkListComponent } },
  { component: { Links: Link } },
  { component: { LinksArea } },
  { component: { LocalityFieldComponent } },
  { component: { LossDoorPage } },
  { component: { MapArea } },
  { component: { MicrositePage } },
  { component: { MinimumRateComponent } },
  { component: { NewsletterRegistrationDownloadComponent } },
  { component: { NpsComponent } },
  { component: { OrganizationComponent } },
  { component: { OrganizationsComponent } },
  { component: { PartnerArea } },
  { component: { Phv2022StageComponent } },
  { component: { Phv2024StageComponent } },
  { component: { PhvBarbaraStageComponent } },
  { component: { PointArea } },
  { component: { PriceCallToActionComponent } },
  { component: { PricePreviewArea } },
  { component: { ProductArea } },
  { component: { ProductDoorPage: DoorPage } },
  { component: { ProductPage } },
  { component: { ProductPrivatePage } },
  { component: { ProductSectionComponent: SectionComponent } },
  { component: { ProductVariantComponent } },
  { component: { ProductVariantsArea } },
  { component: { QuestionComponent } },
  { component: { QuickCheckComponent } },
  { component: { QuoteComponent } },
  { component: { RadioGroupComponent } },
  { component: { RatgeberArea } },
  { component: { RatgeberTeaserComponent } },
  { component: { RatingsArea } },
  { component: { RatingsComponent } },
  { component: { RatingsPage } },
  { component: { RelatedContentArea } },
  { component: { RequirementCalculatorComponent } },
  { component: { RichTextComponent } },
  { component: { SectionComponent } },
  { component: { SelectFieldComponent } },
  { component: { SelectedOrganizationsComponent } },
  { component: { SeoProductPage } },
  { component: { SignetArea } },
  { component: { SignetComponent } },
  { component: { SignetCardsComponent } },
  { component: { SignetSliderComponent } },
  { component: { SitemapArea } },
  { component: { SitemapPage } },
  { component: { SmallCallToActionComponent } },
  { component: { SocialSharingArea } },
  { component: { SolutionArea } },
  { component: { StageArea } },
  { component: { StaticStageComponent } },
  { component: { Sustainability2024StageComponent } },
  { component: { SubHeaderArea } },
  { component: { TeaserArea } },
  { component: { TeaserComponent } },
  { component: { TeaserListComponent } },
  { component: { TelematikStageComponent } },
  { component: { HausratStageComponent } },
  { component: { TestimonialArea } },
  { component: { TestimonialComponent } },
  { component: { TextComponent } },
  { component: { TextFieldComponent } },
  { component: { TileComponent } },
  { component: { TilesComponent } },
  { component: { WhyVHVComponent } },
  { component: { WohngebStageComponent } },
  { component: { YouTubeComponent } }
];

export const getComponentDef = (compName: string): ComponentDef =>
  componentDefs.find(current => !!current.component[compName]);

export const getComponents = () =>
  componentDefs.reduce((prev, current) => Object.assign(prev, current.component), {});
